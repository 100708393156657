// DEPRECATED

import { forwardRef } from 'react'

import A from './A'

import type { ButtonProps } from './A'

const ButtonLinkOld = forwardRef<HTMLAnchorElement, ButtonProps & { primary?: boolean }>(
  ({ primary = true, ...props }, ref) => {
    const C = primary ? A.P : A.S
    return <C ref={ref} {...props} />
  }
)

export default ButtonLinkOld
