import { ChartSquareBarIcon, ChatIcon, LightningBoltIcon } from '@heroicons/react/outline'
import React from 'react'

import useConfig from '@/gf/hooks/useConfig'
import useSignUp from '../hooks/useSignUp'

import Action from '@/gf/components/Action'
import Layout from '../components/LayoutNext'

import { StringParam, useQueryParams } from 'use-query-params'
import LayoutContainer from '../components/LayoutNext/LayoutContainer'
import { SignUpForm } from '../types'

interface Errors {
  membershipId?: string[]
  companyName?: string[]
  name?: string[]
  email?: string[]
  phoneNumber?: string[]
  password?: string[]
  passwordConfirmation?: string[]
  emailOptIn?: string[]
}

const styles = {
  textInput:
    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out text-base',
}

const SignUp = () => {
  const [query, _setQuery] = useQueryParams({
    dealer: StringParam,
    return_path: StringParam,
    company_name: StringParam,
    name: StringParam,
    phone_number: StringParam,
    email: StringParam,
  })
  const [errors, setErrors] = React.useState<Errors>({})
  const [submitting, setSubmitting] = React.useState(false)
  const config = useConfig()
  const signUp = useSignUp()

  const [form, setForm] = React.useState<SignUpForm>({
    membershipId: 'BUSINESS',
    companyName: query.company_name ?? '',
    name: query.name ?? '',
    email: query.email ?? '',
    phoneNumber: query.phone_number ?? '',
    password: '',
    passwordConfirmation: '',
    emailOptIn: true,
    code: '',
    returnPath: query.return_path ?? '',
  })

  const onSubmit = (e, complete) => {
    if (complete) {
      e?.preventDefault()
    }

    setErrors({})
    setSubmitting(true)

    if (complete) {
      signUp(form)
        .then(() => {
          e.target.submit(e, false)
        })
        .catch((err) => {
          setErrors(err.graphQLErrors[0])
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const adjustForm = (e) => {
    if (form.code === '') {
      setForm({
        ...form,
        passwordConfirmation: e.target.value,
        code: `${parseInt('0xFFC1074', 16)}`, // eslint-disable-line prefer-numeric-literals
      })
    } else {
      setForm({ ...form, passwordConfirmation: e.target.value })
    }
  }

  return (
    <Layout>
      <LayoutContainer className="bg-gray-50 text-gray-800 py-16">
        <div className="flex flex-col gap-6 justify-center pb-12 sm:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="/images/android-chrome-512x512.png"
              alt="Logo"
            />
            <h2 className="text-center text-3xl leading-9 font-extrabold">
              Sign up for an account
            </h2>
            <p className="block text-center text-sm leading-5 text-gray-600 max-w-full">
              <a
                href="/login"
                className="text-center text-sm leading-5 max-w text-gearflow hover:text-orange-700 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Existing user? Sign in now.
              </a>
            </p>
          </div>

          <div className="flex flex-col gap-4 lg:flex-row justify-center items-center lg:items-start lg:space-x-8">
            <div className="flex flex-col space-y-2 lg:space-y-6 mx-auto w-full max-w-md text-lg">
              {query.dealer ? (
                <p>
                  You&apos;re so close to getting a better way to manage your parts requests and
                  orders! Create a free account in seconds that will make it easier to:{' '}
                </p>
              ) : (
                <p>
                  It only takes seconds to get access to the competitive advantage for your parts
                  process, including:
                </p>
              )}

              <div className="space-y-2 lg:space-y-4">
                <div className="flex gap-2 items-center">
                  <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                    <LightningBoltIcon className="h-6 w-6" />
                  </div>
                  {query.dealer ? (
                    <p className="text-lg leading-6">Instantly prioritize inbound parts requests</p>
                  ) : (
                    <p className="text-lg leading-6">
                      One place to manage your parts sourcing, from requests to delivery
                    </p>
                  )}
                </div>

                <div className="flex gap-2 items-center">
                  <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                    <ChatIcon className="h-6 w-6" />
                  </div>
                  {query.dealer ? (
                    <p className="text-lg leading-6">Centralize messaging with your customers</p>
                  ) : (
                    <p className="text-lg leading-6">
                      Instant messaging on all requests and orders to cut calls in half
                    </p>
                  )}
                </div>

                <div className="flex gap-2 items-center">
                  <div className="flex items-center justify-center h-10 min-w-10 rounded-md bg-gearflow text-white">
                    <ChartSquareBarIcon className="h-6 w-6" />
                  </div>

                  {query.dealer ? (
                    <p className="text-lg leading-6">
                      Quickly submit and track all orders in real-time
                    </p>
                  ) : (
                    <p className="text-lg leading-6">
                      Real-time insights that fuel equipment uptime and reduce costs (available with
                      Pro + Enterprise plans)
                    </p>
                  )}
                </div>
              </div>

              <p>Simply fill out the form, and you&apos;re in!</p>
            </div>
            <div className="flex flex-col items-stretch justify-center mx-auto w-full max-w-md sm:py-8 sm:px-10 py-4 px-4 bg-white shadow rounded-md sm:rounded-lg">
              <form
                onSubmit={(e) => onSubmit(e, true)}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                id="signup_form"
                action="/login"
                method="post"
                spellCheck="false"
                className="space-y-4"
              >
                <div className="w-full flex flex-1 gap-4">
                  <div className="bg-white radio flex flex-col flex-1 text-base">
                    <input
                      checked={form.membershipId === 'PERSONAL'}
                      onChange={(e) => {
                        if (e.target.checked) setForm({ ...form, membershipId: 'PERSONAL' })
                      }}
                      className="sr-only"
                      id="form_membership_id_PERSONAL"
                      tabIndex={-1}
                      type="radio"
                    />
                    <label
                      className={`${
                        form.membershipId === 'PERSONAL'
                          ? 'hover:border-gearflow'
                          : 'hover:border-gray-600'
                      } border-gray-300 pt-2 pb-25 border border-solid rounded block relative cursor-pointer pl-12`}
                    >
                      <button
                        onClick={() => setForm({ ...form, membershipId: 'PERSONAL' })}
                        type="button"
                        className={`${
                          form.membershipId === 'PERSONAL'
                            ? 'border-gearflow'
                            : 'hover:border-gray-600'
                        } border-gray-300 rounded-full border border-solid h-5 w-5 top-11 left-13 absolute`}
                      >
                        <span
                          className={`rounded-full h-3 w-3 text-center ${
                            form.membershipId === 'PERSONAL' ? 'block bg-gearflow m-auto' : ''
                          }`}
                        />
                      </button>
                      <span>Personal</span>
                    </label>
                  </div>

                  <div className="bg-white radio flex flex-col flex-1 text-base">
                    <input
                      checked={form.membershipId === 'BUSINESS'}
                      onChange={(e) => {
                        if (e.target.checked) setForm({ ...form, membershipId: 'BUSINESS' })
                      }}
                      className="sr-only"
                      id="form_membership_id_BUSINESS"
                      tabIndex={-1}
                      type="radio"
                    />
                    <label
                      className={`${
                        form.membershipId === 'BUSINESS'
                          ? 'hover:border-gearflow'
                          : 'hover:border-gray-600'
                      } border-gray-300 pt-2 pb-25 border border-solid rounded block relative cursor-pointer pl-12`}
                    >
                      <button
                        onClick={() => setForm({ ...form, membershipId: 'BUSINESS' })}
                        type="button"
                        className={`${
                          form.membershipId === 'BUSINESS'
                            ? 'border-gearflow'
                            : 'hover:border-gray-600'
                        } border-gray-300 rounded-full border border-solid h-5 w-5 top-11 left-13 absolute`}
                      >
                        <span
                          className={`rounded-full h-3 w-3 text-center ${
                            form.membershipId === 'BUSINESS' ? 'block bg-gearflow m-auto' : ''
                          }`}
                        />
                      </button>
                      <span>Business</span>
                    </label>
                  </div>
                </div>

                <div
                  className={`flex flex-col md:w-full ${
                    form.membershipId === 'PERSONAL' ? 'hidden' : ''
                  }`}
                >
                  <div className="flex flex-col md:w-full fx relative">
                    <input
                      value={form.companyName}
                      onChange={(e) => setForm({ ...form, companyName: e.target.value })}
                      placeholder="Company Name"
                      type="text"
                      aria-describedby="form_company_name_detail"
                      aria-required="true"
                      className={styles.textInput}
                      id="form_company_name"
                    />
                    <label className="text-gray-500" htmlFor="form_company_name">
                      Company Name
                    </label>
                  </div>
                  {errors.companyName && (
                    <div
                      aria-live="polite"
                      id="form_company_name_detail"
                      className="bg-gray-50 pt-2 pb-2 pl-5 pr-4 rounded rounded-t-none relative block border-t-0 border text-left"
                    >
                      <span className="text-left text-sm">{errors.companyName.join(' / ')}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:w-full">
                  <div className="flex flex-col md:w-full fx relative">
                    <input
                      value={form.name}
                      onChange={(e) => setForm({ ...form, name: e.target.value })}
                      placeholder="First &amp; Last Name"
                      type="text"
                      aria-describedby="form_name_detail"
                      aria-required="true"
                      className={styles.textInput}
                      id="form_name"
                    />
                    <label className="text-gray-500" htmlFor="form_name">
                      First &amp; Last Name
                    </label>
                  </div>
                  {errors.name && (
                    <div
                      aria-live="polite"
                      id="form_company_name_detail"
                      className="bg-gray-50 pt-2 pb-2 pl-5 pr-4 rounded rounded-t-none relative block border-t-0 border text-left"
                    >
                      <span className="text-left text-sm">{errors.name.join(' / ')}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:w-full">
                  <div className="flex flex-col md:w-full fx relative">
                    <input
                      value={form.email}
                      onChange={(e) => setForm({ ...form, email: e.target.value })}
                      placeholder="Email"
                      type="email"
                      aria-describedby="form_email_detail"
                      aria-required="true"
                      className={styles.textInput}
                      id="form_email"
                    />
                    <label className="text-gray-500" htmlFor="form_email">
                      Email
                    </label>
                  </div>
                  {errors.email && (
                    <div
                      aria-live="polite"
                      id="form_company_name_detail"
                      className="bg-gray-50 pt-2 pb-2 pl-5 pr-4 rounded rounded-t-none relative block border-t-0 border text-left"
                    >
                      <span className="text-left text-sm">{errors.email.join(' / ')}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:w-full">
                  <div className="flex flex-col md:w-full fx relative">
                    <input
                      value={form.phoneNumber}
                      onChange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
                      placeholder="Phone Number"
                      type="text"
                      aria-describedby="form_phone_number_detail"
                      aria-required="true"
                      className={styles.textInput}
                      id="form_phone_number"
                    />
                    <label className="text-gray-500" htmlFor="form_phone_number">
                      Phone Number
                    </label>
                  </div>
                  {errors.phoneNumber && (
                    <div
                      aria-live="polite"
                      id="form_company_name_detail"
                      className="bg-gray-50 pt-2 pb-2 pl-5 pr-4 rounded rounded-t-none relative block border-t-0 border text-left"
                    >
                      <span className="text-left text-sm">{errors.phoneNumber.join(' / ')}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:w-full">
                  <div className="flex flex-col md:w-full fx relative">
                    <input
                      value={form.password}
                      onChange={(e) => setForm({ ...form, password: e.target.value })}
                      placeholder="Password"
                      type="password"
                      aria-describedby="form_password_detail"
                      aria-required="true"
                      className={styles.textInput}
                      id="form_password"
                    />
                    <label className="text-gray-500" htmlFor="form_password">
                      Password
                    </label>
                  </div>
                  {errors.password && (
                    <div
                      aria-live="polite"
                      id="form_company_name_detail"
                      className="bg-gray-50 pt-2 pb-2 pl-5 pr-4 rounded rounded-t-none relative block border-t-0 border text-left"
                    >
                      <span className="text-left text-sm">{errors.password.join(' / ')}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:w-full">
                  <div className="flex flex-col md:w-full fx relative">
                    <input
                      value={form.passwordConfirmation}
                      onChange={adjustForm}
                      placeholder="Password Confirmation"
                      type="password"
                      aria-describedby="form_password_confirmation_detail"
                      aria-required="true"
                      className={styles.textInput}
                      id="form_password_confirmation"
                    />
                    <label className="text-gray-500" htmlFor="form_password_confirmation">
                      Password Confirmation
                    </label>
                  </div>
                  {errors.passwordConfirmation && (
                    <div
                      aria-live="polite"
                      id="form_company_name_detail"
                      className="bg-gray-50 pt-2 pb-2 pl-5 pr-4 rounded rounded-t-none relative block border-t-0 border text-left"
                    >
                      <span className="text-left text-sm">
                        {errors.passwordConfirmation.join(' / ')}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:w-full">
                  <div className="flex gap-2 items-center md:w-full">
                    <input
                      checked={form.emailOptIn}
                      onChange={(e) => setForm({ ...form, emailOptIn: e.target.checked })}
                      type="checkbox"
                      aria-describedby="form_email_opt_in"
                      className="rounded border-gray-300 text-gearflow"
                      id="form_email_opt_in"
                    />
                    <label
                      htmlFor="form_email_opt_in"
                      className="text-sm font-medium leading-5 text-gray-700"
                    >
                      Keep me up to date on Gearflow news and updates
                    </label>
                  </div>
                </div>
                <Action.P disabled={submitting} type="submit">
                  {submitting ? <>Signing up&hellip;</> : 'Sign up'}
                </Action.P>
                <div className="flex flex-col md:w-full">
                  <div className="flex items-center pt-4 md:w-full">
                    <label
                      htmlFor="form_sms_agreement"
                      className="text-xs font-medium leading-5 text-gray-600"
                    >
                      By signing up, you authorize Gearflow to send communications (including text
                      messages if applicable) with offers & other information, possibly using
                      automated technology. Consent is not a condition of purchase. Message/data
                      rates apply.
                    </label>
                  </div>
                </div>
                {form.returnPath && (
                  <input name="user[return_path]" type="hidden" value={form.returnPath} />
                )}
                <input name="_csrf_token" type="hidden" value={config.csrfToken} />
                <input name="_method" type="hidden" value="put" />
                <input name="user[email]" type="hidden" id="user_email" value={form.email} />
                <input
                  name="user[password]"
                  id="user_password"
                  type="hidden"
                  value={form.password}
                />
              </form>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </Layout>
  )
}

export default SignUp
