import gearBg2 from '@/retail/components/svgs/gear_bg_2.svg'
import SiteUrls from '@/retail/modules/SiteUrls'
import { motion } from 'framer-motion'
import LayoutContainer from '../../components/LayoutNext/LayoutContainer'
import LinkButton from '../../components/LayoutNext/LinkButton'

const IntroHero = () => (
  <LayoutContainer
    className="how-it-works-top-bg"
    style={{
      backgroundImage: `url(${gearBg2})`,
    }}
    childrenClassName="pt-16 pb-40 sm:pb-[13rem]"
  >
    <section className="flex flex-col md:flex-row gap-8 sm:gap-12 xl:gap-16 sm:items-center">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.5, delayChildren: 0.2, staggerChildren: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="space-y-8 md:order-2"
      >
        <hgroup className="space-y-2">
          <motion.h2 className="text-xl font-medium block">Why Parts Hub Pro?</motion.h2>
          <motion.p
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: { type: 'spring', stiffness: 300, damping: 24 },
              },
              hidden: { opacity: 0, y: -20, transition: { duration: 0.5 } },
            }}
            className="block text-3xl xl:text-5xl leading-9 xl:leading-[3.25rem] font-medium"
          >
            Your competitive edge starts with your parts
          </motion.p>
        </hgroup>
        <motion.p
          variants={{
            visible: {
              opacity: 1,
              y: 0,
            },
            hidden: { opacity: 0, y: -20, transition: { duration: 0.7 } },
          }}
          className="text-base xl:text-xl"
        >
          Reduce your costs, spend less time waiting on parts, and get the insights you need to fuel
          equipment uptime, so your org can make more money and win more jobs.
        </motion.p>
        <div className="gap-x-4 gap-y-2 flex flex-col sm:flex-row items-row sm:items-center justify-start">
          <LinkButton href="/signup">Try It Now!</LinkButton>
          <LinkButton href={SiteUrls.BUYER_SCHEDULE_DEMO_URL} target="_blank" variation="secondary">
            Book a Discovery Call
          </LinkButton>
        </div>
      </motion.div>

      <div className="relative w-full md:w-1/2 md:order-1 flex-shrink-0 rounded-md overflow-hidden shadow-md">
        <div
          className="wistia_responsive_padding"
          style={{ padding: '56.25% 0 0 0', position: 'relative' }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%' }}
          >
            <iframe
              src="https://fast.wistia.net/embed/iframe/wwoqgta0lt?seo=false&videoFoam=true"
              title="How Parts Hub Pro Works Video"
              allow="autoplay; fullscreen"
              frameBorder="0"
              scrolling="no"
              className="wistia_embed"
              name="wistia_embed"
              allowFullScreen
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
        {/* <iframe
          src="https://www.youtube.com/embed/e_S3lkM7kXE?rel=0&cc_load_policy=3&autoplay=1&modestbranding=1&controls=0"
          title="How Parts Hub Pro Works for Your Team and Vendors"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="aspect-[16/9] w-full"
        /> */}
      </div>
    </section>
  </LayoutContainer>
)

export default IntroHero
